.button {
  font-family: Bozon, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  height: 56px;
  width: 320px;
  background: var(--primary);
  border-radius: 50px;

  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: none;

  cursor: pointer;
  transition: all 300ms;

  &:hover {
    &:not([disabled]) {
      background: var(--background);
      color: var(--primary);
    }
  }

  &[disabled] {
    opacity: 0.6;
  }
}

@media screen and (max-width: 800px) {
  .button {
    max-width: '80%';
    width: '80%';
  }
}
